import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { Navigate } from "react-router";
import LoadingPage from "src/components/misc/LoadingPage";
import WithAuth from "src/components/misc/WithAuth";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchBillingDetails } from "src/slices/billingSlice";
import Balance from "./Balance";
import Invoices from "./Invoices";
import PaymentMethods from "./PaymentMethods";
import Plans from "./Plans";

export default function BillingPage() {
  const dispatch = useAppDispatch();
  const billingStatus = useAppSelector((state) => state.billing.status);
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    if (billingStatus === "idle" && client?.user_id) {
      dispatch(fetchBillingDetails(client.user_id));
    }
  }, [billingStatus, dispatch, client?.user_id]);

  if (client?.account.plan.id === "freemium") {
    return <Navigate to="/upgrade-account" />;
  } else if (client?.account.plan.price === 0) {
    return <Navigate to="/schedule" />;
  }

  if (billingStatus === "loading") {
    return <LoadingPage message="Loading billing details" />;
  }

  return (
    <WithAuth>
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "md",
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Billing
        </Typography>
        <Plans sx={{ mb: 4 }} />
        <Balance sx={{ mb: 4 }} />
        <PaymentMethods sx={{ mb: 4 }} />
        <Invoices />
      </Box>
    </WithAuth>
  );
}
